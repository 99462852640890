<template>
	<v-navigation-drawer
			floating
			:stateless="$vuetify.breakpoint.width >= 600"
			color="transparent"
			v-model="drawer"
			app
			overlay-opacity="0.6"
			:width="240"
			right
	>
		<div class="fill-height cell">
			<div class="sub-title-2 d-flex px-3" style="height: 40px; align-items: center">{{ $tc('global.name.widget', 2) }}</div>

			<v-divider class="mx-4" />

			<draggable
					v-model="fields"
					class="pa-3"
					style="height: calc(100% - 41px); overflow: hidden auto"
					:group="{ name: 'field_groups', put: false, pull: true }"
					ghost-class="ghost"
					:sort="false"
			>
				<transition-group name="slide">
					<v-col cols="6" style="min-width: 216px; max-height: 60px" class="pa-2" v-for="(field, i) in fields" :key="field.label">
						<div style="cursor: grab" class="cell2 elevation-2 rounded-lg paragraph text-truncate pa-2">{{ field.label  }}</div>
					</v-col>
				</transition-group>
			</draggable>
		</div>
	</v-navigation-drawer>
</template>

<script>
	export default {
		name: "SideBarRightItem",
		props: ['pevents'],
		components: {
			Button: () => import('@/components/ui/Button.vue'),
			draggable: () => import('vuedraggable')
		},
		data(){
			return {
				fields: []
			}
		},
		computed: {
			drawer: {
				get(){
					return this.$store.getters['dashboard/drawer_item']
				},
				set(val){
					this.$store.commit('dashboard/CHANGE_DRAWER_ITEM_VAL', val)
				}
			}
		},
		watch: {
		},
		methods: {
			setUpHooks(){
				this.pevents.wait('fieldGroups', (groups) => {
					groups.forEach(g => {
						if(g.name === 'default'){
							this.fields = g.fields
						}
					})
				})
			}
		},
		created() {
			this.setUpHooks()
		}
	}
</script>

<style scoped>

</style>
